export const version = '4.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: true,
  realTimeExecutions: false,
  codeEditorThemeDark: 'tokyo_night',
  codeEditorThemeLight: 'github_light',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'card',
  APIBaseURL: process.env.REACT_APP_API_BASE_URL,
  APIWsBaseURL: process.env.REACT_APP_API_WS_BASE_URL
};

export const genericErrorMessage =
  'Something went wrong. We could not process your request.';

export const errorCodes = {
  csrfFailure: 'csrf_failure',
  authenticationRequired: 'authentication_required',
  validationError: 'validation_error'
};
export const nonFieldErrorsKey = '__all__';

export const appPaths = {
  index: '/',
  errors: {
    notFound: '/errors/404/'
  },
  auth: {
    login: '/auth/login/',
    register: '/auth/register/',
    forgotPassword: '/auth/forgot-password/',
    resetPassword: '/auth/reset-password/:userId/:resetToken/',
    verifyEmail: '/auth/verify-email/:verificationToken/'
  },
  agents: {
    list: '/agents/',
    create: '/agents/create/',
    update: '/agents/:id/edit/',
    detail: '/agents/:id/'
  },
  marketplace: {
    list: '/marketplace/'
  },
  bounties: {
    list: '/bounties/',
    create: '/bounties/create/',
    update: '/bounties/:id/edit/',
    detail: '/bounties/:id/'
  },
  agentTemplates: {
    list: '/agent-templates/',
    create: '/agent-templates/create/',
    update: '/agent-templates/:id/edit/',
    detail: '/agent-templates/:id/'
  },
  stores: {
    list: '/stores/',
    create: '/stores/create/',
    detail: '/stores/:id/'
  },
  vectorStores: {
    qdrant: {
      list: '/vector-stores/qdrant/',
      create: '/vector-stores/qdrant/create/',
      detail: '/vector-stores/qdrant/:id/'
    }
  },
  settings: {
    billing: '/settings/billing/',
    apiKeys: '/settings/api-keys/',
    profile: '/settings/profile/',
    updatePreferences: '/settings/profile/preferences/edit/',
    updateUser: '/settings/profile/user/edit/'
  }
};

export const apiPaths = {
  appAgents: {
    agents: {
      list: '/app-agents/agents/',
      create: '/app-agents/agents/create/',
      update: id => `/app-agents/agents/${id}/update/`,
      detail: id => `/app-agents/agents/${id}/`,
      delete: id => `/app-agents/agents/${id}/delete/`
    },
    agentExecutorJobs: {
      create: '/app-agents/agent-executor-jobs/create/'
    },
    agentExecutorCronJobs: {
      list: '/app-agents/agent-executor-cron-jobs/',
      create: '/app-agents/agent-executor-cron-jobs/create/',
      delete: id => `/app-agents/agent-executor-cron-jobs/${id}/delete/`,
      suspend: id => `/app-agents/agent-executor-cron-jobs/${id}/suspend/`,
      unsuspend: id => `/app-agents/agent-executor-cron-jobs/${id}/unsuspend/`,
      cronExpression: '/app-agents/agent-executor-cron-jobs/cron-expression/'
    },
    agentBuilderJobs: {
      latest: '/app-agents/agent-builder-jobs/latest/'
    },
    executions: {
      list: `/app-agents/executions/`,
      cancel: id => `/app-agents/executions/${id}/cancel/`
    },
    builds: {
      detail: id => `/app-agents/builds/${id}/`
    }
  },
  appVectorStores: {
    qdrant: {
      list: '/app-vector_stores/qdrant/',
      create: '/app-vector_stores/qdrant/create/',
      detail: id => `/app-vector_stores/qdrant/${id}/`,
      delete: id => `/app-vector_stores/qdrant/${id}/delete/`
    }
  },
  appBounties: {
    bounties: {
      list: '/app-bounties/bounties/',
      create: '/app-bounties/bounties/create/',
      detail: id => `/app-bounties/bounties/${id}/`,
      update: id => `/app-bounties/bounties/${id}/update/`,
      delete: id => `/app-bounties/bounties/${id}/delete/`
    },
    bountyApplications: {
      create: '/app-bounties/bounty-applications/create/'
    }
  },
  appAgentTemplates: {
    agentTemplates: {
      list: '/app-agent_templates/agent-templates/',
      create: '/app-agent_templates/agent-templates/create/',
      detail: id => `/app-agent_templates/agent-templates/${id}/`,
      update: id => `/app-agent_templates/agent-templates/${id}/update/`,
      delete: id => `/app-agent_templates/agent-templates/${id}/delete/`
    }
  },
  appStores: {
    stores: {
      list: '/app-stores/stores/',
      create: '/app-stores/stores/create/',
      detail: id => `/app-stores/stores/${id}/`,
      delete: id => `/app-stores/stores/${id}/delete/`
    }
  },
  appUsers: {
    users: {
      detail: '/app-users/users/me/',
      delete: '/app-users/users/me/delete/',
      update: '/app-users/users/me/update/'
    },
    userPreferences: {
      detail: '/app-users/user-preferences/my/',
      update: '/app-users/user-preferences/my/update/'
    },
    auth: {
      csrf: '/app-users/auth/csrf/',
      status: '/app-users/auth/status/',
      login: '/app-users/auth/login/',
      logout: '/app-users/auth/logout/',
      register: '/app-users/auth/register/',
      sendPasswordResetLink: '/app-users/auth/send-password-reset-link/',
      resetPassword: '/app-users/auth/reset-password/',
      sendEmailVerificationLink:
        '/app-users/auth/send-email-verification-link/',
      verifyEmail: '/app-users/auth/verify-email/'
    },
    externalAPIKeys: {
      list: '/app-users/external-api-keys/',
      create: '/app-users/external-api-keys/create/',
      delete: id => `/app-users/external-api-keys/${id}/delete/`
    }
  },
  appBilling: {
    accounts: {
      detail: '/app-billing/accounts/my/'
    },
    checkout: {
      url: '/app-billing/checkout/url/'
    },
    balancePurchases: {
      list: '/app-billing/balance-purchases/'
    },
    balancePurchaseOffers: {
      list: '/app-billing/balance-purchase-offers/'
    }
  },
  appStats: {
    executions: {
      hourly: '/app-stats/executions/hourly/',
      daily: '/app-stats/executions/daily/'
    }
  }
};

export const apiWsPaths = {
  appAgents: {
    executions: agentId => `/app-agents/executions/${agentId}/`,
    agentBuilderJobs: agentId => `/app-agents/agent-builder-jobs/${agentId}/`
  }
};

export default {
  version,
  navbarBreakPoint,
  topNavbarBreakpoint,
  settings,
  errorCodes,
  nonFieldErrorsKey,
  genericErrorMessage
};
