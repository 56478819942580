import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Button, Modal, Placeholder } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CreateCronJobForm from 'components/agents/agent-details/cron-jobs/CreateCronJobForm';
import {
  createAgentExecutorJob,
  getLatestAgentBuilderJob
} from 'helpers/requests/agents';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import { apiWsPaths } from 'config';
import { getWsUrl } from 'helpers/ws-requests/ws';
import BuildStatus from 'components/agents/agent-details/panel/BuildStatus';

const PanelBody = ({ loading, agent, agentId, setNewCronJob }) => {
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showExecuteModal, setShowExecuteModal] = useState(false);
  const [executionPayload, setExecutionPayload] = useState('');
  const [isRequestingExecution, setIsRequestingExecution] = useState(false);
  const [latestAgentBuilderJob, setLatestAgentBuilderJob] = useState(null);
  const [agentBuilderJobSocket, setAgentBuilderJobSocket] = useState(null);
  const agentBuilderJobSocketClosedIntentionally = useRef(false);

  let handleGetLatestAgentBuilderJob = async () => {
    const r = await getLatestAgentBuilderJob(agentId);
    if (r.success) {
      setLatestAgentBuilderJob(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  const initializeAgentBuilderJobSocket = () => {
    agentBuilderJobSocketClosedIntentionally.current = false;

    const ws = new WebSocket(
      getWsUrl(apiWsPaths.appAgents.agentBuilderJobs(agentId))
    );
    ws.onmessage = function (e) {
      setLatestAgentBuilderJob(JSON.parse(e.data));
    };
    ws.onclose = function () {
      if (!agentBuilderJobSocketClosedIntentionally.current) {
        console.error(
          'Connection closed unexpectedly. Attempting to reconnect...'
        );
        initializeAgentBuilderJobSocket();
      }
    };

    setAgentBuilderJobSocket(ws);
  };

  const closeAgentBuilderJobSocket = () => {
    if (agentBuilderJobSocket) {
      agentBuilderJobSocketClosedIntentionally.current = true;
      agentBuilderJobSocket.close();
      setAgentBuilderJobSocket(null);
    }
  };

  useEffect(() => {
    if (!loading) {
      initializeAgentBuilderJobSocket();
      handleGetLatestAgentBuilderJob();
    }
    return () => {
      closeAgentBuilderJobSocket();
    };
  }, [loading]);

  const handleCreateAgentExecutorJob = async () => {
    let payload;
    try {
      payload = executionPayload === '' ? null : JSON.parse(executionPayload);
    } catch (error) {
      toast.error('Invalid JSON payload.');
      return;
    }
    setIsRequestingExecution(true);
    const r = await createAgentExecutorJob(agent.id, payload);
    if (r.success) {
      toast.success('Execution has been successfully requested.');
    } else {
      if (r.response.status === 400) {
        // Maybe the agent is being built.
        toast.error(r.response.data.message);
      } else if (r.response.status === 404) {
        toast.error('Agent not found. Maybe it was deleted?');
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setShowExecuteModal(false);
    setExecutionPayload('');
    setIsRequestingExecution(false);
  };

  const handleExecuteModalClose = () => {
    setShowExecuteModal(false);
    setExecutionPayload('');
  };

  const handleExecuteModalOpen = () => {
    setShowExecuteModal(true);
  };

  const handleScheduleModalClose = () => {
    setShowScheduleModal(false);
  };

  const handleScheduleModalOpen = () => {
    setShowScheduleModal(true);
  };

  if (loading) {
    return (
      <Card.Body>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
          <Placeholder xs={1} className="ms-2" />
        </Placeholder>
      </Card.Body>
    );
  }

  return (
    <>
      <Card.Body>
        <Row className="d-flex justify-content-between">
          <Col xs={12} md={6}>
            <SoftBadge bg="info" className="me-2">
              Python {agent.python_version}
            </SoftBadge>
            {latestAgentBuilderJob && (
              <BuildStatus latestAgentBuilderJob={latestAgentBuilderJob} />
            )}
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-md-end mt-3 mt-md-0"
          >
            <Button
              variant="falcon-primary"
              size="sm"
              className="mx-1"
              onClick={handleExecuteModalOpen}
            >
              <FontAwesomeIcon icon="bolt" className="me-1" />
              Execute
            </Button>
            <Button
              variant="falcon-primary"
              size="sm"
              className="ms-1 me-2"
              onClick={handleScheduleModalOpen}
            >
              <FontAwesomeIcon icon="plus" className="me-1" />
              Schedule
            </Button>
            <CopyToClipboard
              text={agent.id}
              onCopy={() => toast.success('Copied to clipboard')}
            >
              <Button variant="falcon-primary" size="sm">
                <FontAwesomeIcon icon="copy" className="me-1" />
                Copy Agent ID
              </Button>
            </CopyToClipboard>
          </Col>
        </Row>
      </Card.Body>
      <Modal
        show={showScheduleModal}
        onHide={handleScheduleModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCronJobForm
            agentId={agent.id}
            setNewCronJob={setNewCronJob}
            handleModalClose={handleScheduleModalClose}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showExecuteModal} onHide={handleExecuteModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Execute Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Payload</label>
            <textarea
              className="form-control"
              rows="5"
              value={executionPayload}
              onChange={e => setExecutionPayload(e.target.value)}
            />
            <small className="text-muted">
              Optional JSON payload to be passed to the agent.
            </small>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              onClick={handleCreateAgentExecutorJob}
              disabled={isRequestingExecution}
            >
              {isRequestingExecution ? 'Processing...' : 'Execute'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PanelBody;
